<!--
  aes-des 编码解码 组件
-->
<template>
	<div class="md5-tools-page">
		<md5-header />
		<main class="md5-tools-box">
			<div class="aes-des-component">
				<a
					href="javascript:history.back(-1)"
					rel="noopener noreferrer"
					class="goback"
					:title="$t('lang.gobackBack')"
				>
					<i class="el-icon-back"></i>
					{{ $t('lang.goback') }}
				</a>
				<h3 class="title">{{ h3 }}</h3>
				<el-row>
					<el-col :span="24">
						<el-tabs v-model="type" type="border-card" @tab-click="handleClick">
							<el-tab-pane
								:label="item.name"
								:name="item.type"
								:key="index"
								v-for="(item, index) in cryptList"
							>
								<el-row>
									<el-col :span="24" class="col">
										<el-input
											type="textarea"
											v-model.trim="value"
											rows="10"
											:placeholder="$t('lang.websocket.ciphertext')"
											:class="textareaState ? 'warn-active' : ''"
											@focus="textareaState = false"
										></el-input>
									</el-col>
									<el-col :span="24" class="col content-button">
										<el-input
											v-model.trim="key"
											:placeholder="$t('lang.key')"
											class="content-input"
											:class="inputState ? 'warn-active-input' : ''"
											@focus="inputState = false"
										></el-input>
										<div class="submit" @click="encrypt">
											<a href="javascript:void(0)">{{ $t('lang.encrypt') }}</a>
										</div>
										<div class="submit" @click="decode">
											<a href="javascript:void(0)">{{
												$t('lang.websocket.decode')
											}}</a>
										</div>
										<div class="submit" @click="copy">
											<a href="javascript:void(0)">{{
												$t('lang.copyResult')
											}}</a>
										</div>
										<div class="submit" @click="clear">
											<a href="javascript:void(0)">{{ $t('lang.clear') }}</a>
										</div>
									</el-col>
									<el-col :span="24" class="col result-textarea">
										<el-input
											type="textarea"
											v-model.trim="result"
											rows="10"
											:placeholder="$t('lang.result')"
											readonly
										></el-input>
									</el-col>
								</el-row>
							</el-tab-pane>
						</el-tabs>
					</el-col>
				</el-row>
			</div>
		</main>
		<md5-footer />
	</div>
</template>

<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
import CryptoJS from 'crypto-js';
import { get_tools_detail } from '@/api/tools';

export default {
	name: 'aes_des',
	components: {
		md5Header,
		md5Footer,
	},
	data() {
		return {
			cryptList: [
				{ name: this.$t('lang.websocket.aes'), type: 'aes' },
				{ name: this.$t('lang.websocket.des'), type: 'des' },
			],
			type: 'aes',
			value: '',
			result: '',
			key: '',
			textareaState: false,
			inputState: false,
			h3: '',
			data: {},
		};
	},
	methods: {
		getToolsDetail() {
			get_tools_detail({ id: this.$route.query.id }).then((res) => {
				if (res) {
					this.h3 = res.name;
				}
			});
		},
		clear() {
			this.value = '';
			this.result = '';
			this.key = '';
		},
		handleClick() {
			switch (this.type) {
				case 'aes':
					this.h3 = '';
					break;
				case 'des':
					this.h3 = this.$t('lang.websocket.des');
					break;
			}
			this.value = this.result = this.key = '';

			this.$EventBus.$emit(this.$Global.ONLINE_TOOLS_TITLE_EVENT, this.type);
		},
		copy() {
			this.copyData(this.result);
			document.querySelector('.result-textarea .el-textarea__inner').select(); //复制 获得焦点
		},
		encrypt() {
			if (!this.key) {
				this.inputState = true;
				this.$message.error(this.$t('lang.websocket.error3'));
			} else {
				switch (this.type) {
					case 'aes':
						this.result = CryptoJS.AES.encrypt(this.value, this.key).toString();
						break;
					case 'des':
						this.result = CryptoJS.DES.encrypt(this.value, this.key).toString();
						break;
				}
			}
		},
		//解密
		decode() {
			//判断解密空值
			if (!this.value) {
				if (!this.key) {
					this.inputState = true;
					this.$message.error(this.$t('lang.websocket.error3'));
				} else {
					this.textareaState = true;
					this.$message.error(this.$t('lang.websocket.error4'));
					return;
				}
			} else {
				if (!this.key) {
					this.inputState = true;
					this.$message.error(this.$t('lang.websocket.error3'));
					return;
				}
			}

			switch (this.type) {
				case 'aes':
					this.result = CryptoJS.AES.decrypt(this.value, this.key).toString(
						CryptoJS.enc.Utf8
					);
					break;
				case 'des':
					this.result = CryptoJS.DES.decrypt(this.value, this.key).toString(
						CryptoJS.enc.Utf8
					);
					break;
			}
		},
		//筛选出  AES类型,DES类型 组件
		switchActiveType() {
			if (this.$route.query.id.indexOf('AES') !== -1) {
				this.type = 'aes';
			} else {
				this.type = 'des';
			}
		},
	},
	created() {
		this.getToolsDetail();
		this.switchActiveType();
	},
};
</script>

<style lang="scss" scoped>
.goback {
	float: right;
	font-size: 16px;
	font-weight: 400;
	color: rgba(68, 169, 254, 1);
	cursor: pointer;
	display: flex;
	align-items: center;
	i {
		font-size: 18px;
	}
	&:hover {
		color: #37bef0;
	}
}
.md5-tools-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: url(../../assets/img/cmd-index-bg.png) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
	.md5-tools-box {
		flex: 1;
		padding-top: 30px;
		width: 1208px;
		margin: 0 auto;
	}
	// 标题
	.title {
		width: 100%;
		height: 60px;
		font-size: 30px;
		font-weight: bold;
		color: #333333;
		line-height: 36px;
		text-align: center;
	}

	.aes-des-component {
		width: 100%;
		margin-top: 10px;
		height: auto;
		background: #ffffff;
		box-shadow: 0px 0px 20px 0px rgba(68, 169, 254, 0.15);
		padding: 35px;
		border-radius: 6px;
		.col {
			margin-top: 20px;
			.content-input {
				width: 62%;
			}
		}

		.content-button {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.submit {
				width: 80px;
				height: 36px;
				border-radius: 6px;
				text-align: center;
				padding-top: 9px;
				background: linear-gradient(
					0deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(240, 248, 255, 1) 100%
				);
				border: 1px solid rgba(255, 255, 255, 1);
				margin-left: 24px;
				box-shadow: 0px 0px 10px 0px rgba(79, 94, 106, 0.15);
			}
			.submit a {
				color: rgba(22, 148, 250, 1);
			}
		}
		// 内侧最大盒子
		/deep/ .el-tabs--border-card {
			border-radius: 6px;
			box-shadow: none;
			.el-tabs__content {
				padding-top: 0px;
			}
			// AES 加密/解密
			.el-tabs__header {
				border-bottom: 0px;
				border-radius: 6px 6px 0 0;
				background: rgba(229, 229, 229, 0.3);
				.el-tabs__item.is-active {
					color: rgba(56, 56, 56, 1);
					background-color: #fff;
					border-right-color: #fff;
					border-left-color: #fff;
					border-radius: 10px 6px 0 0;
				}
				.el-tabs__item:not(.is-disabled):hover {
					color: rgba(56, 56, 56, 1);
				}
			}
		}

		/deep/ .el-input__inner:focus {
			border-color: #409eff !important;
			outline: 0;
		}
		/deep/ .el-input__inner {
			border: 1px solid #dcdfe6 !important;
			border-radius: 6px;
		}
		/deep/ .el-textarea__inner {
			resize: none;
			min-height: 33.2222px;
			padding-top: 19px;
			padding-left: 25px;
			border-radius: 6px;
			font-family: '';
		}
		.warn-active {
			/deep/ .el-textarea__inner {
				border: 1px solid #f56c6c;
			}
		}
		.warn-active-input {
			/deep/ .el-input__inner {
				border: 1px solid #f56c6c;
			}
		}
	}
}

@keyframes pulse {
	0% {
		transform: scaleX(1);
	}

	50% {
		transform: scale3d(1.03, 1.03, 1.03);
	}

	to {
		transform: scaleX(1);
	}
}
</style>
